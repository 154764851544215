@use '../../styles/colors';

.new-item-form {
  display: flex;
  justify-content: center;
  gap: 0.25rem;
  background-color: colors.$bg-color;
  padding: 0 1rem 2rem 1rem;
}

.item-input {
  height: 4rem;
  width: 100%;
  max-width: 50rem;
  padding: 0 1rem;
}
