@use '../../styles/colors';
@use '../../styles/typography';

.button {
  padding: 1rem 2rem;
  color: white;
  background-color: colors.$primary-color;
  font-size: 1.6rem;
  display: inline-block;
  font-weight: typography.$weight-bold;
  border-radius: 5px;
  border: none;
  transition: background-color 300ms;
  cursor: pointer;
}

.button:hover {
  background-color: colors.$primary-color-shade-1;
}

.secondary {
  background-color: #f1f4ff;
  color: #333;
  border: 1px solid #ddd;
}

.secondary:hover {
  background-color: #e4e9ff;
}
