.container {
  padding: 0 1rem;
}

.info {
  color: #fff;
  text-align: center;
  font-size: 1.6rem;
  margin-top: 5rem;
  margin-bottom: 2rem;
  line-height: 1.6;
}

.code-input {
  height: 4rem;
  width: 100%;
  max-width: 50rem;
  padding: 0 1rem;
  display: block;
  margin: 0 auto 2rem auto;
}

.submit-button {
  display: block;
  margin: 0 auto !important;
}
