.house-icon {
  font-size: 3.2rem;
}

.list {
  list-style: none;
  padding: 1rem;
}

.list-link {
  color: #fff;
  text-decoration: none;
  height: 20rem;
}

.list-card {
  display: flex;
  height: 6rem;
  align-items: center;
  justify-content: space-between;
}

.trash-icon {
  margin-right: 8px;
}
