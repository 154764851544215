@use './styles/colors';

.app {
  background-color: colors.$bg-color;
  max-height: 100vh;
}

.container {
  max-width: 130rem;
  margin: 0 auto;
}
