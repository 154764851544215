@use '../../styles/colors';

.shopping-list {
  position: relative;
}

.arrow-back {
  font-size: 3.2rem;
  color: #fff;
}

.items-list {
  list-style: none;
  padding: 1rem 1rem 10rem 1rem;
}
