@use '../../styles/typography';
@use '../../styles/colors';

.in-basket {
  text-decoration: line-through;
  background-color: #777 !important;
  font-weight: 400;
}

.container {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
}

.icon-white {
  color: #fff;
  opacity: 1;
}

.actions {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.actions svg {
  cursor: pointer;
}

.actions svg:last-child {
  margin-right: 0;
}

.checkbox {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid currentColor;
  border-radius: 0.15em;
  display: grid;
  place-content: center;
  cursor: pointer;
}

.checkbox::before {
  content: '';
  width: 1em;
  height: 1em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em black;
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

.checkbox:checked::before {
  transform: scale(1);
}
