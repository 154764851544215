.clear-button {
  position: fixed;
  bottom: 0;
  right: 0;
  text-align: center;
  padding: 2rem 4rem;
  font-size: 1.6rem;
  display: block;
  width: 100%;
  text-transform: uppercase;
}
