@use '../../styles/colors';

.top-bar {
  color: white;
  font-size: 1.6rem;
  gap: 2rem;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  background-color: colors.$bg-color;
}

.children-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 1rem;
}
