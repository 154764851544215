@use '../../styles/colors';

.main-header {
  background-color: colors.$header-bg;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem;
  color: white;
}
