.label {
  text-align: center;
  font-size: 2.4rem;
  margin-bottom: 3rem;
  color: #fff;
}

.actions {
  display: flex;
  gap: 2rem;
  justify-content: flex-end;
}
