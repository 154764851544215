@use '../../styles/typography';
@use '../../styles/colors';

.list-item {
  text-align: start;
  font-size: 1.6rem;
  margin: 0 auto 1rem auto;
  color: #fff;
  font-weight: typography.$weight-normal;
  transition: all 300ms;
  background-color: colors.$card-bg;
  cursor: pointer;
}

.list-item:hover {
  transform: scale(1.01);
}

.list-item.in-basket {
  text-decoration: line-through;
  background-color: #777;
  font-weight: 400;
}
